<template>
  <label class="govuk-label">
    <slot />
  </label>
</template>

<script>
export default {
  name: "GovLabel"
};
</script>
